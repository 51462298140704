import { ERROR_CODES } from "@/libs/error/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserUpdate } from "libs/schema/user";
import axiosWithCredentials from "../commons";
import { CURRENT_USER } from "./reactQueryCacheKeys";
import { CURRENT_USER_API } from "./user/common";
import { useSession } from "next-auth/react";
import useIsFeatureFlagEnabled from "@/src/services/optimizely/useIsFeatureFlagEnabled";
import { OptimizelyFeatureFlags } from "@/src/services/optimizely/optimizelyFeatureFlags";

export const useUpdateCurrentUser = (
  onError?: (response: any) => void,
  onSuccess?: () => void,
) => {
  const { enabled: optimizeUserCallsEnabled, clientReady } =
    useIsFeatureFlagEnabled(OptimizelyFeatureFlags.OPTIMIZE_USER_CALLS);
  const queryClient = useQueryClient();
  const { update } = useSession();
  return useMutation(
    (updatedUser: UserUpdate) =>
      axiosWithCredentials.put(CURRENT_USER_API, { ...updatedUser }),
    {
      onSuccess: async () => {
        onSuccess && onSuccess();
        const onSuccessCall = optimizeUserCallsEnabled
          ? update
          : () => queryClient.invalidateQueries([CURRENT_USER]);
        return await onSuccessCall();
      },
      onError({ response }) {
        return onError && onError(response);
      },
      useErrorBoundary: ({ response }) =>
        response !== undefined &&
        response?.status >= ERROR_CODES.INTERNAL_ERROR,
    },
  );
};
