/* eslint-disable @typescript-eslint/no-unsafe-member-access */

// webpack barfs on using types from `process` without transpiling
// import { env } from "process";

// export interface ProcessEnv extends NodeJS.ProcessEnv {
//   // TODO address within upcoming refactor for `NODE_ENV` consistency
//   NODE_ENV: string;
// }

// access-codes mask + generated code length
export const CODE_MASK = "ABCDEFGHJKLMNPQRSTUVWXYZ0123456789";
export const NULL_WALLET_ADDRESS = "0x0000000000000000000000000000000000000000";
export const CODE_LENGTH = 12;

const countryDefault = "US";
const languageDefault = "en";

/*** Legal ***/
export const ROUTE_LEGAL_DIGITAL_COLLECTIBLE_TERMS =
  "/legal/digital-collectible-terms";
export const ROUTE_LEGAL_TERMS_OF_SERVICE = "/legal/terms-of-service";
export const ROUTE_LEGAL_PRIVACY_POLICY = "/legal/privacy-policy";
export const ROUTE_LEGAL_TERMS_OF_SALE = "/legal/terms-of-sale";
export const ROUTE_LEGAL_TERMS_OF_USE = "/legal/terms-of-use";

export const legalURLDigitalCollectibleTerms = (
  country?: string,
  language?: string,
): string => {
  return `https://agreementservice.svs.nike.com/rest/agreement?agreementType=NvsDigCollectible&uxId=swoosh.nike&requestType=redirect&country=${
    country ? country : countryDefault
  }&language=${language ? language : languageDefault}`;
};
export const legalURLTermsOfService = (
  country?: string,
  language?: string,
): string => {
  return `https://agreementservice.svs.nike.com/rest/agreement?agreementType=NvsTermsOfService&uxId=swoosh.nike&requestType=redirect&country=${
    country ? country : countryDefault
  }&language=${language ? language : languageDefault}`;
};

export const legalURLTermsOfSale = (
  country?: string,
  language?: string,
): string => {
  return `https://agreementservice.svs.nike.com/rest/agreement?agreementType=NvsTermsOfSale&uxId=swoosh.nike&requestType=redirect&country=${
    country ? country : countryDefault
  }&language=${language ? language : languageDefault}`;
};

export const legalURLTermsOfUse = (
  country?: string,
  language?: string,
): string => {
  return `https://agreementservice.svs.nike.com/rest/agreement?agreementType=TermsOfUse&uxId=swoosh.nike&requestType=redirect&country=${
    country ? country : countryDefault
  }&language=${language ? language : languageDefault}`;
};

export const legalURLPrivacyPolicy = (
  country?: string,
  language?: string,
): string => {
  return `https://agreementservice.svs.nike.com/rest/agreement?agreementType=PrivacyPolicy&uxId=swoosh.nike&requestType=redirect&country=${
    country ? country : countryDefault
  }&language=${language ? language : languageDefault}`;
};

export const content = {
  // for gql collections, # of collections per page
  defaultLimit: 25,
};

const defaultCacheMins = 5;
const toSeconds = (a?: number): number => (a ?? 0) * 60;
export const cache = {
  // all inputs are in minutes
  // serve-stale:
  // - helps cover upstream 429s, eg. from alchemy & contentful
  // - keeps responses fresh-as-possible
  // - akamai's docs. don't mention support for `stale-if-error`,
  // - ...but it seems we get similar behavior depending on our settings
  headers: (
    mins: number = defaultCacheMins,
    { isPrivate, staleWhileRevalidate } = {
      isPrivate: false,
      staleWhileRevalidate: 5, // 5 mins
    },
  ): object =>
    // Akamai doesn't allow spaces in the synthax of the header here
    ({
      "Edge-Control": `${
        isPrivate ? "private" : "public"
      },!no-store,max-age=${toSeconds(mins)},stale-while-revalidate=${toSeconds(
        staleWhileRevalidate,
      )}`,
    }),
};

export const cors = {
  allowedOrigins: [
    "http://localhost:3000",

    "https://swoosh.nike",
    "https://swoosh-dev.nike",
    "https://omobo-test.nike.com",
    "https://omobo.nike.com",
    // url for achievement admin custom app.
    "https://ab3f86ae-ee8c-4154-bcc4-94b738d23215.ctfcloud.net",
    // url for collections dashboard
    "https://6a726294-e5b5-4b93-9aaa-844135bb9845.ctfcloud.net",
    //attempt at regex for contentful:
    /^https:\/\/[0-9a-zA-Z.-]+\.ctfcloud\.net$/,
    // ...other static values
    /^https:\/\/[0-9a-zA-Z.-]+\.swoosh\.nike$/,
    /^https:\/\/[0-9a-zA-Z.-]+\.swoosh-dev\.nike$/,
    /^https:\/\/[0-9a-zA-Z.-]+\.swoosh-dev\.nike:3001$/,
    /^https:\/\/[0-9a-zA-Z.-]+\.nvs-test\.nikecloud\.com$/,
    /^https:\/\/[0-9a-zA-Z.-]+\.nvs-prod\.nikecloud\.com$/,
    /^https:\/\/[0-9a-zA-Z.-]+\.nvs-test\.nikecloud\.com:3001$/,
  ],

  // WARNING - This is used by all requests, OPTIONS and
  // other non OPTIONS request - it should not include caching headers
  generateCorsHeaders: (origin: string): Record<string, string> => {
    const isAllowedOrigin = allowOrigin(origin, cors.allowedOrigins);
    if (isAllowedOrigin) {
      return {
        "Access-Control-Allow-Headers":
          "content-type,x-api-key,accept,authorization,newrelic,traceparent,tracestate,access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,access-control-max-age,bitgo-sdk-version,bitgo-auth-version,request-id,if-modified-since",
        "Access-Control-Allow-Origin": origin,
        Vary: "Origin",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "OPTIONS,GET,POST,PATCH,PUT,DELETE",
        "Access-Control-Max-Age": "300",
      };
    } else {
      return {
        "Access-Control-Allow-Origin": "false",
        Vary: "Origin",
        "Access-Control-Allow-Credentials": "false",
        "Access-Control-Max-Age": "300",
      };
    }
  },
  allowOrigin,
};

function allowOrigin(
  requestOrigin: string,
  allowedOrigins: (string | RegExp)[],
): boolean {
  const matchingOrigin = allowedOrigins.find((origin) => {
    if (typeof origin === "string") {
      return origin === requestOrigin;
    }

    // if the value in the array is not a string, it should be a regular expression
    return origin.test(requestOrigin);
  });

  return !!matchingOrigin;
}

export const supportedEmeaCountryCodes: Record<string, string> = {
  AT: "AT",
  BE: "BE",
  CZ: "CZ",
  DK: "DK",
  FI: "FI",
  FR: "FR",
  DE: "DE",
  GR: "GR",
  HU: "HU",
  IE: "IE",
  IT: "IT",
  LU: "LU",
  NL: "NL",
  PL: "PL",
  PT: "PT",
  SI: "SI",
  ES: "ES",
  SE: "SE",
  GB: "GB",
};

export const isCountryInSupportedCountriesList = (country: string): boolean => {
  return countrySupportedList.includes(country);
};

export const isCountryInSupportedEMEACountriesList = (
  country: string,
): boolean =>
  Object.prototype.hasOwnProperty.call(supportedEmeaCountryCodes, country);

export const countrySupportedList = [
  "US",
  ...Object.keys(supportedEmeaCountryCodes),
];

type Country = string; // 2 letter country code

export interface AkamaiGeoCookie {
  country?: Country;
  region?: string;
  timezone?: string;
  latitude?: number;
  longitude?: number;
}

// Geoloc cookie is of the format:
// cc=US,rc=WA,tp=vhigh,tz=PST,la=47.6115,lo=-122.3343

/**
 * Method to parse Geo Cookies set by akamai
 *
 * Note: Performance Could be improved by removing splits and reading the cookie string
 * as parsing once
 *
 * @param geoCookie cookie string of the format `cc=US,rc=WA,tp=vhigh,tz=PST,la=47.6115,lo=-122.3343`
 */
export const parseGeoCookie = (geoCookie: string): AkamaiGeoCookie => {
  const toParse = geoCookie.split(",");
  const akamaiGeoCookie: AkamaiGeoCookie = {};

  for (const property of toParse) {
    const parsed = property.split("=");
    if (parsed.length === 2) {
      const key = parsed[0];
      if (key === "cc") {
        akamaiGeoCookie.country = parsed[1].trim();
      } else if (key === "rc") {
        akamaiGeoCookie.region = parsed[1].trim();
      } else if (key === "tz") {
        akamaiGeoCookie.timezone = parsed[1].trim();
      } else if (key === "la") {
        try {
          akamaiGeoCookie.latitude = parseFloat(parsed[1].trim());
        } catch {
          // eslint-disable-next-line no-console
          console.error(
            `Could not parse parsed[1]: ${parsed[1]} to float. Cookie value: ${geoCookie}`,
          );
        }
      } else if (key === "lo") {
        try {
          akamaiGeoCookie.longitude = parseFloat(parsed[1].trim());
        } catch {
          // eslint-disable-next-line no-console
          console.error(
            `Could not parse parsed[1]: ${parsed[1]} to float. Cookie value: ${geoCookie}`,
          );
        }
      }
    }
  }

  return akamaiGeoCookie;
};

export default {
  supportedEmeaCountryCodes,
  isCountryInSupportedCountriesList,
};
